.react-tel-input .form-control {
    padding-left: 0;
}

.react-tel-input .selected-flag {
    height: 32px;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: transparent;
}
